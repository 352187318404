/*
 * @Author: JunLiang.Wang 
 * @Date: 2021-12-17 17:05:44 
 * @Last Modified by: JunLiang.Wang
 * @Last Modified time: 2021-12-21 18:22:57
 * @props: (name)logo display name
 * @methods: (logoClick) This event is triggered when the logo is clicked
 */

<template>
  <figure class="row ver-hor-center" @click="logoClick">
    <!--svg图标,因为要变更图标颜色，所以直接放svg代码
        根据class来变更图标fill的颜色
    -->
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 110 110"
      enable-background="new 0 0 110 110"
      xml:space="preserve"
      style="background: transparent; height: 100%"
    >
      <g>
        <path
          fill="#9E1900"
          d="M53,56.9c1.8,1.2,3,3.2,3,5.5c0,4.1-3.7,7.3-8,6.4c-2.5-0.5-4.5-2.5-5-5c-0.6-2.9,0.7-5.5,2.8-6.9v-5.7
		H34.3v23.2c0,2.1,1.7,3.9,3.9,3.9h22.7c2.1,0,3.9-1.7,3.9-3.9V51.2H53V56.9z"
        />
        <path
          class="logo"
          fill="#222"
          d="M58.7,86.2H40.4c-2.4,0-4.3-1.9-4.3-4.3v-3.6c0-1,0.8-1.8,1.8-1.8h23.3c1,0,1.8,0.8,1.8,1.8v3.6
		C63.1,84.3,61.1,86.2,58.7,86.2z M39.7,80.1v1.7c0,0.3,0.3,0.6,0.6,0.6h18.4c0.3,0,0.6-0.3,0.6-0.6v-1.7H39.7z"
        />
        <path
          class="logo"
          fill="#222"
          d="M69.7,73h-4.9c-1,0-1.8-0.8-1.8-1.8V49.3c0-1,0.8-1.8,1.8-1.8h4.9c3.1,0,5.5,2.5,5.5,5.5v14.4
		C75.3,70.5,72.8,73,69.7,73z M66.6,69.3h3.1c1,0,1.8-0.8,1.8-1.8V53c0-1-0.8-1.8-1.8-1.8h-3.1V69.3z"
        />
        <path
          class="logo"
          fill="#222"
          d="M61.5,80.1H37.6c-2.8,0-5.2-2.3-5.2-5.2V41.8c0-1,0.8-1.8,1.8-1.8h11.6c1,0,1.8,0.8,1.8,1.8v15.2
		c0,0.6-0.3,1.2-0.8,1.5c-1.3,0.9-2.1,2.4-2.1,3.9c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-1.6-0.8-3.1-2.1-3.9
		c-0.5-0.3-0.8-0.9-0.8-1.5V41.8c0-1,0.8-1.8,1.8-1.8h11.7c1,0,1.8,0.8,1.8,1.8V75C66.6,77.8,64.3,80.1,61.5,80.1z M36.2,43.6V75
		c0,0.8,0.7,1.5,1.5,1.5h23.9c0.8,0,1.5-0.7,1.5-1.5V43.6h-8V56c1.9,1.6,3,3.9,3,6.4c0,4.6-3.8,8.4-8.4,8.4s-8.4-3.8-8.4-8.4
		c0-2.5,1.1-4.8,3-6.4V43.6H36.2z"
        />
        <path
          class="logo"
          fill="#222"
          d="M49.5,70.8c-0.6,0-1.2-0.1-1.8-0.2c-3.2-0.7-5.8-3.2-6.4-6.4C40.6,61.1,41.7,58,44,56V43.6h-7.9v4.8
		c0,2.9-2.4,5.3-5.3,5.3c-2.9,0-5.3-2.4-5.3-5.3v-9.3c-2.4-3.3-2.6-7.7-0.7-11.6c1.6-3.1,4.7-5.1,8.2-5.4c1.2-2.6,3.9-4.4,6.8-4.4
		c1.2,0,2.4,0.3,3.5,0.9c2.5-2,5.5-3.1,8.8-3.1c6.3,0,11.8,4.2,13.5,10.1c4.1,1.3,6.8,5.3,6.4,9.6c-0.4,4.7-4.6,8.4-9.4,8.4h-7.7V56
		c1.9,1.6,3,3.9,3,6.4c0,2.5-1.1,4.9-3.1,6.5C53.3,70.2,51.4,70.8,49.5,70.8z M34.3,39.9h11.6c1,0,1.8,0.8,1.8,1.8v15.2
		c0,0.6-0.3,1.2-0.8,1.5c-1.7,1.1-2.4,3-2,5c0.4,1.8,1.8,3.2,3.6,3.6c1.5,0.3,2.9,0,4-0.9c1.1-0.9,1.7-2.2,1.7-3.7
		c0-1.6-0.8-3.1-2.1-3.9c-0.5-0.3-0.8-0.9-0.8-1.5V41.8c0-1,0.8-1.8,1.8-1.8h9.5c3,0,5.5-2.2,5.7-5c0.3-2.9-1.8-5.5-4.6-5.9
		c-0.8-0.1-1.4-0.7-1.5-1.5c-1-4.8-5.2-8.3-10.2-8.3c-2.8,0-5.4,1.1-7.3,3c-0.7,0.7-1.8,0.7-2.5,0.1c-0.5-0.4-1.3-0.9-2.4-0.9
		c-1.8,0-3.3,1.2-3.7,3c-0.2,0.9-1,1.5-1.9,1.4c-2.6-0.1-5,1.2-6.1,3.4C26.7,32,27,35,28.8,37.3c0.3,0.3,0.4,0.7,0.4,1.2v9.9
		c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-6.6C32.5,40.7,33.3,39.9,34.3,39.9z"
        />
        <path
          class="logo"
          fill="#222"
          d="M44.5,51.8h-1.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h1.2c0.3,0,0.6,0.3,0.6,0.6
		C45.1,51.6,44.8,51.8,44.5,51.8z M40.8,51.8c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6
		C41.4,51.6,41.2,51.8,40.8,51.8z M37.1,51.8h-1.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h1.2c0.3,0,0.6,0.3,0.6,0.6
		C37.7,51.6,37.5,51.8,37.1,51.8z"
        />
        <path
          class="logo"
          fill="#222"
          d="M63.4,51.8h-1.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h1.2c0.3,0,0.6,0.3,0.6,0.6
		C64,51.6,63.7,51.8,63.4,51.8z M59.7,51.8c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6
		C60.3,51.6,60,51.8,59.7,51.8z M56,51.8h-1.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6H56c0.3,0,0.6,0.3,0.6,0.6
		C56.6,51.6,56.3,51.8,56,51.8z"
        />
      </g>
    </svg>
    <!----------------------------------------->

    <!--链接-->
    <a class="title">{{ $t(langPre+name) }}</a>
    <!-------->
  </figure>
</template>
  
<script>
export default {
  name: "logo",
  props: {
    //链接显示名称
    name: {
      type: String,
      default: "",
    },
    langPre:{
      type:String,
      default:"navigation."
    }
  },
  methods: {
    //logo被点击事件
    logoClick() {
      this.$emit("logoClick");
    },
  },
};
</script>

<style scoped>

.dark .logo {
  fill: #fafafa;
}
.light .logo {
  fill: #1d1d1f;
}

figure {
  margin: 0;
  height: 100%;
  cursor: pointer;
}
figure:hover > svg {
  -webkit-animation: rotate-in-center 0.11s ease-in-out 4 alternate-reverse none;
  animation: rotate-in-center 0.11s ease-in-out 4 alternate-reverse none;
}
figure:hover {
  transform: scale(1.1);
}

.title {
  font-size: 1.6em;
  font-weight: bold;
  color: #9e1900;
  background: transparent;
}

@-webkit-keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}
@keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}

@media (max-width: 730px) {
  .title {
    font-size: 1.3em;
  }
}
</style>