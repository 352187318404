<!--
 * @Description: 主页
 * @Author: JunLiangWang
 * @Date: 2023-03-01 11:47:35
 * @LastEditors: JunLiangWang
 * @LastEditTime: 2023-03-01 16:34:03
-->
<template>
  <main class="main">
    <!--头部导航栏-->
    <navigation
      :list="config.navList"
      :title="config.webTitle"
      :langPre="config.langPre.navigation"
      :languageList="config.languageList"
    >
      <foot
        :footTitle="config.footTitle"
        :langPre="config.langPre.foot"
        :linkList="config.footList"
      ></foot>
    </navigation>
    <!------------->

    <!--主要内容-->
    <article>
      <transition name="fadeInUp" mode="out-in">
        <router-view />
      </transition>
    </article>
    <!----------->

    <!--脚步导航栏-->
    <foot
      :footTitle="config.footTitle"
      :langPre="config.langPre.foot"
      :linkList="config.footList"
    ></foot>
    <!------------->
  </main>
</template>

<script>
import navigation from "@/components/navigation/navigation.vue";
import foot from "@/components/foot/foot.vue";
export default {
  name: "mainPage",
  components: {
    navigation,
    foot,
  },
};
</script>

<style scoped>
.navigation {
  height: 100px;
}

footer {
  height: 100px;
}

article {
  min-height: calc(100% - 200px);
  display: flex;
  justify-content: center;
  position: relative;
}
</style>